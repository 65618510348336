import { useCallback, useEffect, useState } from 'react';

const useBreakpointValue = () => {
  const [breakpoint, setBreakpoint] = useState('');

  const calculateSize = useCallback((innerWidth: number) => {
    let size = '';
    if (innerWidth < 768) {
      size = 'sm';
    } else if (innerWidth > 768 && innerWidth < 1024) {
      size = 'md';
    } else if (innerWidth > 1024 && innerWidth < 1366) {
      size = 'lg';
    } else {
      size = 'xl';
    }

    return size;
  }, []);

  useEffect(() => {
    setBreakpoint(calculateSize(window.innerWidth));

    const handleWindowResize = () => {
      setBreakpoint(calculateSize(window.innerWidth));
    };
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [calculateSize]);

  return breakpoint;
};

export default useBreakpointValue;
