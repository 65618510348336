import time from '@flyer/utils/time';

function useShowNetworkSpeedLow() {
  const now = time();
  const startTimeToday = time().hour(19).minute(0).second(0); // 20:00 hôm nay
  const endTimeToday = time().hour(23).minute(59).second(59); // 23:59:59 hôm nay
  const startTimeTomorrow = time().hour(0).minute(0).second(0);
  const endTimeTomorrow = time().hour(3).minute(59).second(59);
  return (
    now.isBetween(startTimeToday, endTimeToday, null, '[]') ||
    now.isBetween(startTimeTomorrow, endTimeTomorrow, null, '[]')
  );
}
export default useShowNetworkSpeedLow;
