import Image, { StaticImageData } from 'next/image';
import React from 'react';
import { Button } from '@/components/common';
import NewBg from '../assets/new.svg';

function ItemMenu({
  onClick,
  title,
  image: imageIcon,
  showNew,
  iconsClassName,
  isGif,
  buttonStyle,
}: {
  onClick: () => void;
  title: string;
  image: React.ReactNode | StaticImageData;
  showNew: boolean;
  iconsClassName: string;
  isGif?: boolean;
  buttonStyle?: React.CSSProperties;
}) {
  return (
    <div>
      <Button
        shape="square"
        className="z-[10] sm:rounded-[12px] xl:rounded-[16px] uppercase w-[82px] h-[72px] sm:w-[4.5vw] sm:h-[4.5vw] md:w-[5vw] md:h-[5vw] lg:w-[55px] lg:h-[52px] xl:w-[72px] xl:!h-[69px] 2xl:w-[93px] 2xl:!h-[86px]"
        variant="polish"
        colorScheme="indigo"
        onClick={onClick}
        animation={false}
        style={buttonStyle}
      >
        {showNew && (
          <NewBg className="absolute z-10 sm:top-[-2vw] w-[25px] -left-[6px] -top-[6px] lg:top-[-14px] sm:-left-[1vw] lg:-left-[9px] md:w-[40px] lg:w-[35px] xl:w-[50px] 2xl:w-[60px] 2xl:top-[-22px] 2xl:-left-[19px]" />
        )}

        <div className="wrap-content item flex flex-col mt-[-5px] sm:h-[4.5vw] md:h-[37px] lg:h-[52px] xl:h-[64px] 2xl:h-[75px] xl:mt-[-6px]  2xl:mt-[-7px] justify-between">
          <div className="relative flex justify-center">
            {isGif ? (
              (imageIcon as React.ReactNode)
            ) : (
              <Image src={imageIcon as StaticImageData} alt="flyer" className={iconsClassName} />
            )}
          </div>
          <span className="relative flex font-extrabold items-center justify-center sm:text-[0.7vw] lg:text-[7px] xl:text-[9px] 2xl:-mt-1">
            {title}
          </span>
        </div>
      </Button>
    </div>
  );
}
export default ItemMenu;
