import RankingIcon from '@/assets/icons/ic-ranking.png';
import { useModal } from '@flyer/components';
import { useAuth } from '@/providers/auth';
import time from '@flyer/utils/time';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { Button } from '@/components/common';
import { ActiveAccountPopupLazy } from '@/components/popups';
import NoAccessModal from '@/components/popups/NoAccessModal';

export default function ButtonActiveAccount() {
  const { t } = useTranslation('common');
  const { toggleModal: toggleActiveAccount } = useModal(ActiveAccountPopupLazy);
  const { toggleModal: toggleAccess } = useModal(NoAccessModal);
  const { user } = useAuth();
  const currentDay = time();
  const dayExpire = time(user?.subscriptionExpiresAt);

  return (
    <Button
      onClick={user ? toggleActiveAccount : toggleAccess}
      icon={RankingIcon}
      classNameText="flex items-center justify-center pl-[2vw] text-[1vw] xl:text-[11px] xl:pl-[20px] uppercase"
      classNameIcon="w-[4vw] left-[-0.9vw] top-[-1.5vw] xl:w-[51px] xl:top-[-15px] xl:left-[-21px]"
      className="w-[15vw] h-[3vw] xl:h-[32px] xl:w-[170px]"
      showNotifyActive={!user?.isSubscribing || dayExpire.diff(currentDay, 'days') < 7}
    >
      {user?.isSubscribing ? t('exam.extend_account') : t('exam.active_account')}
    </Button>
  );
}
