import { useMemo } from 'react';
import { useAuth } from '@/providers/auth';
import { useRemoteConfig } from '@/providers/remote-config';

export default function useUserLocalTest() {
  const remoteConfig = useRemoteConfig();
  const { user } = useAuth();
  return useMemo(
    () => (user?.phoneNumber ? remoteConfig.localTest?.[user.phoneNumber] : undefined),
    [remoteConfig.localTest, user?.phoneNumber],
  );
}
