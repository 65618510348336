import { dataHome } from '@/components/exam/ExamHomeView/dataMock';
import ROUTES from '@/lib/routes';
import { TextFit } from '@flyer/components';
import BgImage from '@flyer/components/next/BgImage';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo } from 'react';
import { useSwiper, useSwiperSlide } from 'swiper/react';

export default function ExamPlanetStatic({
  data,
  index,
  swipeLength,
}: {
  data: (typeof dataHome)[number];
  index: number;
  swipeLength: number;
}) {
  const swipeSlider = useSwiperSlide();
  const swipe = useSwiper();
  const router = useRouter();
  const { lang } = useTranslation('common');

  const { image, name, ribbon, description, descriptionEn, active, slug } = data;

  const renderLangDescription = useMemo(() => {
    if (lang === 'en') {
      return descriptionEn;
    }
    return description;
  }, [description, descriptionEn, lang]);

  const handleOnClick = useCallback(() => {
    if (swipeSlider.isActive) {
      if (slug) {
        void router.push(`${ROUTES.TEST_LEVEL_EXAM(slug)}`);
      }
    } else {
      swipe.slideToLoop(index);
    }
  }, [index, router, slug, swipe, swipeSlider.isActive]);

  return (
    <div
      className={clsx(
        'select-none cursor-pointer',
        [!active && swipeSlider.isVisible && 'grayscale-[90%]'],
        [
          swipeSlider.isVisible &&
            !swipeSlider.isPrev &&
            !swipeSlider.isNext &&
            !swipeSlider.isActive &&
            'sm:opacity-[0.25] sm:translate-x-[0%] sm:translate-y-[10%] sm:scale-[0.55]',
        ],
        [
          swipeSlider.isPrev &&
            !swipeSlider.isNext &&
            !swipeSlider.isActive &&
            'opacity-[0.7] translate-x-[-10%] sm:translate-x-[-20%] translate-y-[2%] sm:translate-y-[10%] scale-[0.80]',
        ],
        [
          swipeSlider.isNext &&
            !swipeSlider.isPrev &&
            !swipeSlider.isActive &&
            'opacity-[0.7] translate-x-[10%] sm:translate-x-[20%] translate-y-[2%] sm:translate-y-[10%] scale-[0.80]',
        ],
        [swipeSlider.isActive && 'opacity-[1] w-[140%] h-[140%] translate-x-[-10%]'],
      )}
    >
      {swipeSlider.isVisible &&
      swipeSlider.isActive &&
      !swipeSlider.isPrev &&
      !swipeSlider.isNext ? (
        <div className="relative select-none" onClick={handleOnClick} aria-hidden>
          <BgImage
            src={image}
            priority={swipeSlider.isActive}
            alt="flyer"
            imageClassName="select-none"
          >
            <BgImage
              src={ribbon}
              alt="flyer"
              wrapClassName="bottom-[-6%] w-[80%] translate-x-[10%] translate-y-[-5%] !absolute "
            >
              <TextFit
                className="text-center text-white font-extrabold uppercase"
                fontSize={8.5}
                lineHeight={1.3}
                as="h1"
              >
                {name}
              </TextFit>

              <TextFit
                tooltipClassName="w-[200px] sm:w-full"
                fontSize={5}
                className="description title m-auto !w-[70%] text-white font-[700]"
              >
                {renderLangDescription}
              </TextFit>
            </BgImage>
          </BgImage>
        </div>
      ) : null}

      <div
        aria-hidden
        onClick={handleOnClick}
        className={clsx([swipeSlider.isVisible && !swipeSlider.isActive ? 'block' : 'hidden'])}
      >
        <BgImage
          src={image}
          priority={swipeSlider.isActive}
          alt="flyer"
          imageClassName="select-none"
        >
          <BgImage src={ribbon} alt="flyer" wrapClassName="bottom-[-10%] !absolute ">
            <TextFit
              className="text-white font-extrabold uppercase"
              fontSize={8.5}
              lineHeight={1.3}
              as="h1"
            >
              {name}
            </TextFit>

            <TextFit
              tooltipClassName="w-[200px] sm:w-full"
              fontSize={5}
              className="description title m-auto !w-[70%] text-white font-[700]"
            >
              {renderLangDescription}
            </TextFit>
          </BgImage>
        </BgImage>
      </div>
      <span className="opacity-[0]">.</span>
    </div>
  );
}
