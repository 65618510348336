import useTranslation from 'next-translate/useTranslation';
import Image, { StaticImageData } from 'next/image';
import { useCallback, useState } from 'react';
import parentImg from '../assets/parent.png';
import teacherImg from '../assets/teacher.png';

type Props = { open: boolean; onClose: () => void };

enum EnumPartnerRole {
  TEACHER = 'teacher',
  PARENT = 'parent',
}

function Role({
  img,
  title,
  desc,
  onClick,
}: {
  img: StaticImageData;
  title: string;
  desc: string;
  onClick?: () => void;
}) {
  return (
    <div
      role="button"
      tabIndex={-1}
      onClick={onClick}
      onKeyDown={undefined}
      className="w-full h-full flex items-center justify-center gap-x-4 px-[17px] hover:bg-opacity-[0.12] hover:bg-[#000000]"
    >
      <Image src={img} alt={title} className="w-[119px] h-[auto]" />
      <div>
        <h3 className="text-[20px] leading-[14px] font-extrabold text-white mb-3 whitespace-nowrap">
          {title}
        </h3>
        <p className="text-[12px] font-semibold leading-4 text-[#F7C4E3] w-[131px]">{desc}</p>
      </div>
    </div>
  );
}

function ChooseRoleModal({ open, onClose }: Props) {
  const { t, lang } = useTranslation('modal');

  const [loading, setLoading] = useState(false);

  const handleClickRole = useCallback(
    (role: EnumPartnerRole) => {
      const { origin } = window.location;
      setLoading(true);
      const currentUrl = `${origin}/partner/${lang}`;
      const url =
        role === EnumPartnerRole.PARENT
          ? `/parent/${lang}`
          : `/partner/${lang}/?auth_redirect_url=${currentUrl}`;
      window.open(url, '_blank');
      setLoading(false);
    },
    [lang],
  );

  if (!open) return null;

  return (
    <div
      role="button"
      tabIndex={-1}
      className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-[9999] cursor-default"
      onClick={loading ? undefined : onClose}
      onKeyDown={undefined}
    >
      <div
        role="button"
        tabIndex={-1}
        className="w-[325px] rounded-[20px] flex flex-col cursor-default relative max-md:scale-[0.9] max-sm:scale-[0.7]"
        style={{
          background: 'linear-gradient(150deg, #FE8383 0.29%, #AC58E1 99.71%)',
          boxShadow: '0px -8px 0px 0px #803DA4 inset',
        }}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={undefined}
      >
        <div className="h-[225px]">
          <Role
            img={parentImg}
            title={t('choose_partner_role.i_am_parent')}
            desc={t('choose_partner_role.parent_desc')}
            onClick={() => (loading ? undefined : handleClickRole(EnumPartnerRole.PARENT))}
          />
        </div>
        <div className="bg-[#702570] bg-opacity-10 h-[5px]" />
        <div className="flex-grow h-[225px]">
          <Role
            img={teacherImg}
            title={t('choose_partner_role.i_am_teacher')}
            desc={t('choose_partner_role.teacher_desc')}
            onClick={() => (loading ? undefined : handleClickRole(EnumPartnerRole.TEACHER))}
          />
        </div>

        <button
          type="button"
          onClick={loading ? undefined : onClose}
          className="absolute -bottom-[34px] text-white left-[50%] -translate-x-[50%] px-2 py-[2px] hover:underline rounded text-lg font-bold"
        >
          {t('choose_partner_role.no_thank')}
        </button>
      </div>
    </div>
  );
}

export default ChooseRoleModal;
