import useTranslation from 'next-translate/useTranslation';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { Popover } from '@flyer/components';
import ItemMenu from '@/components/exam/ExamHomeView/ExamHeader/ItemMenu';
import ROUTES from '@/lib/routes';
import IconClose from '@/assets/svg/ic-close-black.svg';
import { useGetChallengeByCodeQueryLazyQuery } from '@/graphql/generated/exam-old-query';
import challengeIcon from '@/assets/icons/ic-challenge.png';
import { Button, Input } from '@/components/common';
import HeaderPopover from './ExamHeader/HeaderPopover';

function JoinChallengePopover() {
  const { t } = useTranslation('popover');
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState('');
  const [isShowing, setIsShowing] = useState(true);
  const router = useRouter();
  const [joinChallenge, { loading: joinChallengeLoading }] = useGetChallengeByCodeQueryLazyQuery({
    onCompleted(data) {
      if (!data.challenges[0]) {
        setError(t('error:label.join_challenge_invalid_code'));
        return;
      }
      void router.push(`${ROUTES.JOIN_CHALLENGE(data.challenges[0].code)}`);
    },
  });

  const handleJoinChallengeClick = useCallback(() => {
    if (!code) {
      setError(t('error:label.join_challenge_empty_code'));
      return;
    }
    setError('');
    void joinChallenge({
      variables: {
        code: code.trim(),
      },
    });
  }, [code, joinChallenge, t]);

  const handleCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
    setError('');
  }, []);

  const handlerClickJoinChallenge = useCallback(() => {
    void router.push(`${ROUTES.JOIN_CREATE_CHALLENGE}`);
  }, [router]);

  return (
    <Popover
      zIndex={1}
      trigger={isShowing ? 'always' : 'none'}
      placement="bottom-start"
      fallbackPlacements={['bottom-end', 'left']}
      contentClassName="p-0"
      arrowSize={15}
      content={
        <div className="shadow-lg bg-[linear-gradient(180deg,#0134A7_0%,#002B8B_100%)] rounded-lg relative sm:pt-[14px] xl:pt-[14px] sm:pb-[8px] sm:px-[8px] xl:pl-[10px] xl:pr-[25px] 2xl:pb-3">
          <button
            type="button"
            className="border-0 bg-transparent absolute top-1 right-1 z-[1]"
            onClick={() => setIsShowing(false)}
          >
            <IconClose
              width={17}
              height={17}
              alt="modal close"
              fill="white"
              className="transition-colors duration-300 hover:fill-[#37F9F7] w-[15px] h-[15px] sm:w-[12px] sm:h-[12px] xl:w-[17px] xl:h-[17px]"
            />
          </button>
          <div className="relative flex flex-row  p-[15px] sm:p-0 justify-center items-baseline">
            <div className="wrap-input w-[170px]  sm:w-[70px] md:w-[100px] lg:w-[100px] xl:w-[115px] 1.5xl:w-[150px] 2xl:w-[190px]">
              <Input
                placeholder={t('join_challenge.label.input_placeholder')}
                className="text-center text-white  sm:p-[3px] sm:text-[7px] sm:min-h-[20px] md:text-[8px] lg:text-[10px] lg:p-1 md:min-h-[30px] xl:p-2 2xl:min-h-[42px] 2xl:text-[14px]"
                value={code}
                onChange={handleCodeChange}
                onEnter={handleJoinChallengeClick}
                wrapClassName="text-center"
              />
              {error && (
                <p className="text-sm 2xl:text-[14px] leading-[20px] sm:leading-[15px] xl:leading-[20px]  text-red-600 my-1">
                  {error}
                </p>
              )}
            </div>

            <Button
              colorScheme="red"
              className="px-9 py-2.5 mx-2 w-auto sm:py-[2px] md:py-[5px] sm:px-[8px] sm:rounded-[2px] sm:text-[7px] md:text-[8px] md:rounded-[4px] xl:text-[12px] xl:py-[6px] 2xl:px-5 2xl:py-[0.6rem]"
              onClick={handleJoinChallengeClick}
              isLoading={joinChallengeLoading}
            >
              {t('join_challenge.label.button')}
            </Button>
          </div>
        </div>
      }
    >
      <div>
        <HeaderPopover hoverText={t('menu_hover_text.challenge')}>
          <ItemMenu
            showNew={false}
            onClick={handlerClickJoinChallenge}
            title={t('common:exam.challenge')}
            image={challengeIcon}
            iconsClassName="w-[46px] h-[35px] sm:w-[3vw] sm:h-[3vw] lg:w-[35px] lg:h-[35px] xl:w-[64px] xl:h-[51px] object-contain"
          />
        </HeaderPopover>
      </div>
    </Popover>
  );
}

export default JoinChallengePopover;
