import { Popover } from '@flyer/components';

type Props = {
  children: React.ReactNode;
  hoverText?: string;
};

function HeaderPopover({ children, hoverText }: Props) {
  return (
    <Popover
      zIndex={20}
      trigger={hoverText ? 'hover' : 'none'}
      contentClassName="p-1 px-2"
      fallbackPlacements={['left', 'right']}
      showArrow
      content={<div className="text-xs">{hoverText}</div>}
    >
      <div>{children}</div>
    </Popover>
  );
}

export default HeaderPopover;
