import { Popover, TextFit } from '@flyer/components';
import BgImage from '@flyer/components/next/BgImage';
import { twMerge } from 'tailwind-merge';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSwiper, useSwiperSlide } from 'swiper/react';
import Lottie from 'lottie-react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import ROUTES from '@/lib/routes';
import { dataHome } from '@/components/exam/ExamHomeView/dataMock';

export default function ExamPlanet({
  data,
  index,
  swipeLength,
}: {
  data: (typeof dataHome)[number];
  index: number;
  swipeLength: number;
}) {
  const swipeSlider = useSwiperSlide();
  const swipe = useSwiper();
  const router = useRouter();
  const { lang } = useTranslation('common');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { getUris, name, ribbon, description, descriptionEn, active, slug } = data;
  const [uris, setUris] = useState<any>();

  const renderLangDescription = useMemo(() => {
    if (lang === 'en') {
      return descriptionEn;
    }
    return description;
  }, [description, descriptionEn, lang]);

  const handleOnClick = useCallback(() => {
    if (swipeSlider.isActive) {
      if (slug) {
        void router.push(`${ROUTES.TEST_LEVEL_EXAM(slug)}`);
      }
    } else {
      swipe.slideToLoop(index);
    }
  }, [index, router, slug, swipe, swipeSlider.isActive]);

  const swipeSliderClass = useMemo(() => {
    if (
      swipeSlider.isVisible &&
      !swipeSlider.isPrev &&
      !swipeSlider.isNext &&
      !swipeSlider.isActive
    )
      return 'sm:opacity-[0.5] sm:-translate-x-[40%] scale-[1.4]';
    if (swipeSlider.isPrev && !swipeSlider.isNext && !swipeSlider.isActive)
      return 'opacity-[0.7] -translate-x-[0%] sm:-translate-x-[65%]';
    if (swipeSlider.isNext && !swipeSlider.isPrev && !swipeSlider.isActive)
      return 'opacity-[0.7] translate-x-[-20%] sm:translate-x-[20%]';
    if (swipeSlider.isActive)
      return 'opacity-[1] translate-x-[-10%] sm:-translate-x-[23%] overflow-visible';
    return '';
  }, [swipeSlider]);

  useEffect(() => {
    void getUris().then((uri: any) => setUris(uri));
  }, [getUris]);

  return (
    <div
      className={twMerge(
        'select-none cursor-pointer overflow-visible',
        swipeSliderClass,
        active ? '' : 'grayscale-[90%]',
      )}
    >
      {uris && swipeSlider.isVisible ? (
        <div className="relative select-none overflow-visible" onClick={handleOnClick} aria-hidden>
          <Lottie
            animationData={uris}
            loop
            className={twMerge(
              'flex justify-center z-[1] select-none w-[60vw] h-[60vw] sm:w-[29vw] sm:h-[29vw]',
            )}
          />

          <BgImage
            src={ribbon}
            alt="flyer"
            wrapClassName="sm:bottom-[6%] sm:left-[25%] bottom-[3%] left-[5%] absolute z-[1] select-none sm:w-[115%] w-[110%]"
            imageClassName="select-none"
          >
            <TextFit
              className="text-center text-white font-extrabold uppercase"
              fontSize={8.5}
              lineHeight={renderLangDescription ? 1.3 : 2}
              as="h1"
            >
              {name}
            </TextFit>

            <svg viewBox="0 0 100 7" className="w-full">
              <foreignObject width="100%" height="100%">
                <Popover
                  content={renderLangDescription}
                  onlyShowOnEllipsis
                  contentClassName="w-[200px] sm:w-full capitalize"
                >
                  <p
                    className={twMerge(
                      'mt-[0px] mx-auto leading-[1] description w-[78%] text-[5px] title text-white text-center font-[700] capitalize',
                    )}
                  >
                    {renderLangDescription}
                  </p>
                </Popover>
              </foreignObject>
            </svg>
          </BgImage>
        </div>
      ) : null}

      <span className="opacity-[0]">.</span>
    </div>
  );
}
